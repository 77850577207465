import React, { useContext, useCallback, useState, useEffect } from "react";
import { css } from "@emotion/react";

import Card from "react-bootstrap/Card";
import { Trans } from "react-i18next";
import Slider from "rc-slider";
import cn from "classnames";
import { formatPrize } from "utils";
import SliderHandle from "./SliderHandle";
import debounce from "lodash/debounce";
import useQueryParams from "hooks/useQueryParams";
import { useAppSelector } from "redux/Store";

import PrizeCalculatorItem from "./PrizeCalculatorItem";
// import tournamentsAPI from "actions/api/tournaments";
import { WindowSizeContext } from "providers/WindowSizeProvider";
import api from "actions/api";

interface IPrizeCalculatorProps {
  tournament: ITournament;
  minRange: number;
  maxRange: number;
  defaultValue: number;
  color: boolean;
}

export default function PrizeList({ tournament, minRange, maxRange, defaultValue, color }: IPrizeCalculatorProps) {
  const [sliderValue, setSliderValue] = useState(defaultValue);
  const [prizes, setPrizes] = useState<any[]>([]);
  const { width } = useContext(WindowSizeContext);
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const queryParams = useQueryParams();

  const calculateTotalPrize = () => {
    let total = 0;
    for (let i = 0; i < prizes.length; i++) {
      const { prize, rank_from, rank_to } = prizes[i];
      const pprize = prize.split(" ").reverse()[0];
      total += parseFloat(pprize) * (rank_to + 1 - rank_from);
    }
    return formatPrize(tournament, total.toFixed(2));
  };

  function fetchPrizes(participantCount: number) {
    api
      .get(`tournaments/${tournament?.id}/prizes/${participantCount}`)
      .then(response => (setPrizes(response.data), console.log("ddd", response)))
      .catch(() => setPrizes([]));
  }
  // eslint-disable-next-line
  const debouncedFetchPrizes = useCallback(debounce(fetchPrizes, 1500), []);

  function handleSliderChange(value: number) {
    setSliderValue(value);
    debouncedFetchPrizes(value);
  }

  useEffect(() => {
    fetchPrizes(defaultValue);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (tournament?.prize_calculation === "dynamic") {
  //     fetchPrizes(defaultValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [defaultValue, tournament?.prize_calculation]);

  if (tournament?.prize_calculation === "manual" && prizes?.length === 0) return null;
  if (tournament?.prize_calculation === "dynamic") maxRange = tournament?.max_participant_required;
  if (!prizes) return null;

  return (
    <Card
      css={isDesktop && queryParams.get("my-games") === "true" ? playGameCss : cardStyle}
      className={cn("prize-list-container", { desktop: width > 1022 })}
    >
      {(tournament?.prize_calculation === "automatic" || tournament?.prize_calculation === "dynamic") && (
        <Card.Body>
          <div className="row align-items-center py-3 px-1">
            <div className=" col-xs-3 text-center px-3 label-style" css={color ? labelStylePrize : labelStyle}>
              <span>
                <b>
                  {sliderValue}/{tournament?.max_participant || "Unlimited"}
                </b>
              </span>
              <br />
              <span className="fontColor">
                <Trans i18nKey="players">Players</Trans>
              </span>
            </div>
            <div className="col" />
            <div className=" col-xs-3 text-center px-3" css={color ? labelStylePrize : labelStyle}>
              <span className="text-center label-style">
                <b>{calculateTotalPrize()}</b>
              </span>
              <br />
              <span className="fontColor">
                <Trans i18nKey="total-prize">Total Prize</Trans>
              </span>
            </div>
          </div>
          <div className={`row py-3 ${color && "tap-prize-color"}`} css={sliderMark}>
            <div className="col-2 text-center mark-style">
              <span>0</span>
            </div>
            <div className="col-8 text-center py-2 px-0">
              <Slider
                min={minRange}
                max={maxRange}
                defaultValue={defaultValue}
                handle={SliderHandle}
                onChange={handleSliderChange}
              />
            </div>
            {/* this style can only work here..need to check */}
            <div className="col-2 text-center mark-style" style={{ padding: "0" }}>
              <span>{maxRange}+</span>
            </div>
          </div>
        </Card.Body>
      )}

      <div className="card-prize">
        {prizes.length > 0 &&
          prizes.map((p, index) => <PrizeCalculatorItem key={index} tournament={tournament} prize={p} />)}
      </div>
    </Card>
  );
}

const labelStyle = (theme: ITheme) => css`
  font-size: 14px;
  color: ${theme.text.primary};
  .fontColor {
    color: ${theme.text.secondary};
    @media (max-width: 360px) {
      font-size: 12px;
    }
  }
`;

const labelStylePrize = (theme: ITheme) => css`
  font-size: 15px;
  color: ${theme.foreground.primary};
  .fontColor {
    color: ${theme.foreground.primary};
    @media (max-width: 360px) {
      font-size: 12px;
    }
  }
`;

const sliderMark = (theme: ITheme) => css`
  color: ${theme.text.primary};
  &.tap-prize-color {
    color: ${theme.foreground.primary};
  }
  .mark-style {
    padding: 0;
  }
`;

const playGameCss = (theme: ITheme) => css`
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: ${theme.foreground.primary};
  margin-top: -10px;

  &.desktop {
    margin-top: -20px;
  }

  .card-prize {
    overflow-y: scroll;
  }

  .rc-slider {
    .rc-slider-handle {
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );
      border-color: #fff;
      cursor: grab;
      &:active {
        box-shadow: 0 0 5px rgba(255, 0, 0, 0.2);
      }
    }
    .rc-slider-track {
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );
    }
  }
`;

const cardStyle = (theme: ITheme) => css`
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: ${theme.foreground.primary};
  z-index: 1;
  margin-top: -10px;

  &.desktop {
    margin-top: -68px;
  }

  .card-prize {
    overflow-y: scroll;
  }

  .rc-slider {
    .rc-slider-handle {
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );
      border-color: #fff;
      cursor: grab;
      &:active {
        box-shadow: 0 0 5px rgba(255, 0, 0, 0.2);
      }
    }
    .rc-slider-track {
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      );
    }
  }
`;
